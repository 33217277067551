var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'd-flex',
    'flex-grow-1',
    'justify-start',
    'align-center',
    'skrsearchfield__wrapper',
    { '-active': _setup.isSearchActive },
  ]},[_c('v-text-field',{ref:"search",class:['skrsearchfield skrsearchfield__mawidth', { 'd-none': _vm.$vuetify.breakpoint.xsOnly && !_setup.isSearchActive }],attrs:{"clearable":"","dense":"","rounded":"","hide-details":"","placeholder":_vm.hint,"prepend-inner-icon":"$vuetify.icons.magnify","filled":"","data-cy":"searchTextField"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _setup.resetSearch.apply(null, arguments)},"blur":function($event){_setup.isSearchActive = false},"focus":function($event){_setup.isSearchActive = true}},model:{value:(_setup.query),callback:function ($$v) {_setup.query=$$v},expression:"query"}}),_vm._v(" "),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_setup.isSearchActive && _vm.$vuetify.breakpoint.xsOnly),expression:"!isSearchActive && $vuetify.breakpoint.xsOnly"}],staticClass:"ma-0 | skrsearchfield__show",attrs:{"icon":"","color":"text"},on:{"click":function($event){_setup.isSearchActive = true}}},[_c('v-icon',[_vm._v("$vuetify.icons.magnify")])],1),_vm._v(" "),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_setup.isSearchActive && _vm.$vuetify.breakpoint.xsOnly),expression:"isSearchActive && $vuetify.breakpoint.xsOnly"}],staticClass:"ma-0 | skrsearchfield__cancel",attrs:{"text":"","color":"text"},on:{"click":_setup.resetSearch}},[_vm._v("\n    "+_vm._s(_setup.t('global.cancel'))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }