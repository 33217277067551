var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"document-preview",class:{
    'is-selected': _vm.selected,
    'is-active': _vm.active || _vm.loading,
    'is-disabled': _vm.disabled,
  }},[_c('nuxt-link',{staticClass:"document-preview__link",class:_vm.elevationClass,attrs:{"to":{
      name: 'view-srid',
      params: { srid: _vm.signatureRequest.id },
    }}},[_c('skr-image',{staticClass:"document-preview__image",staticStyle:{"max-width":"150px"},attrs:{"src":_vm.documentPagePreviewURL(_vm.signatureRequest.documentId, 0, 20),"height":200,"lazy":true}}),_vm._v(" "),_c('div',{staticClass:"document-preview__details",attrs:{"data-cy":"docPreviewDetails"}},[_c('div',{staticClass:"document-preview__title",attrs:{"data-cy":"docPreviewTitle"}},[_vm._v("\n        "+_vm._s(_vm.signatureRequest.title)+"\n      ")]),_vm._v(" "),_c('status-chip',{staticClass:"mt-2",attrs:{"status":_vm.status}})],1)],1),_vm._v(" "),_c('v-checkbox',{staticClass:"mt-0",class:{ 'ml-7': _vm.showAutoDeleteIndicator },attrs:{"value":_vm.selected,"disabled":_vm.disabled,"color":"primary","data-cy":"batchCheckbox","on-icon":"$vuetify.icons.checkbox_active","off-icon":"$vuetify.icons.checkbox_inactive","hide-details":""},on:{"click":function($event){return _vm.$emit('select', !_vm.selected)}}}),_vm._v(" "),(_vm.showAutoDeleteIndicator || _vm.showLegacyAutoDeleteIndicator)?_c('v-tooltip',{attrs:{"top":"","color":"error","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"auto-deletion-icon",attrs:{"color":"error"}},on),[_vm._v(" $vuetify.icons.auto_delete ")])]}}],null,false,4277135980)},[_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(_vm.$tc('home.deleted_in', _vm.daysUntilDeletion && _vm.daysUntilDeletion > 1 ? 0 : 1, {
          n: _vm.daysUntilDeletion,
        }))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"document-preview__info link",class:{ 'is-hidden': _vm.loading },attrs:{"data-cy":"docPreviewInfoButton"},on:{"click":function($event){return _vm.$emit('activate')}}},[_c('v-icon',[_vm._v("$vuetify.icons.info")])],1),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"document-preview__loading-details"},[_c('spinner-small')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }