import "core-js/modules/es.array.includes.js";
import differenceInDays from 'date-fns/differenceInDays';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import SkrImage from '@/components/SkrImage.vue';
import SpinnerSmall from '@/components/SpinnerSmall.vue';
import StatusChip from '@/components/StatusChip.vue';
import useDocumentUrl from '@/composables/useDocumentUrl';
import useSignatureHelpers from '@/composables/useSignatureHelpers';
import { useBusinessStore } from '@/stores/business';
import { useDocumentStore } from '@/stores/document';
import { useUtilStore } from '@/stores/util';
export default defineComponent({
  components: {
    SpinnerSmall: SpinnerSmall,
    SkrImage: SkrImage,
    StatusChip: StatusChip
  },
  props: {
    signatureRequest: {
      type: Object,
      required: true
    },
    multiSelect: Boolean,
    selected: Boolean,
    disabled: Boolean,
    loading: Boolean,
    active: Boolean
  },
  setup: function setup() {
    var _useDocumentStore = useDocumentStore(),
      resetActiveSignatureRequest = _useDocumentStore.resetActiveSignatureRequest;
    var _useDocumentUrl = useDocumentUrl(),
      documentPagePreviewURL = _useDocumentUrl.documentPagePreviewURL;
    var _useSignatureHelpers = useSignatureHelpers(),
      displayStatusOverallFn = _useSignatureHelpers.displayStatusOverallFn;
    var _useBusinessStore = useBusinessStore(),
      companyHasAutoDeletionEnabled = _useBusinessStore.companyHasAutoDeletionEnabled,
      companyHasLegacyAutoDeletion = _useBusinessStore.companyHasLegacyAutoDeletion,
      companyIsEnterprise = _useBusinessStore.companyIsEnterprise;
    var utilStore = useUtilStore();
    var now = computed(function () {
      return utilStore.now;
    });
    return {
      documentPagePreviewURL: documentPagePreviewURL,
      displayStatusOverallFn: displayStatusOverallFn,
      resetActiveSignatureRequest: resetActiveSignatureRequest,
      now: now,
      companyHasAutoDeletionEnabled: companyHasAutoDeletionEnabled,
      companyHasLegacyAutoDeletion: companyHasLegacyAutoDeletion,
      companyIsEnterprise: companyIsEnterprise
    };
  },
  data: function data() {
    return {
      daysUntilDeletion: null
    };
  },
  computed: {
    status: function status() {
      return this.displayStatusOverallFn(this.signatureRequest);
    },
    elevationClass: function elevationClass() {
      if (this.active || this.loading) {
        return 'elevation-8';
      } else if (this.disabled) {
        return 'elevation-0';
      } else {
        return 'elevation-3';
      }
    },
    showAutoDeleteIndicator: function showAutoDeleteIndicator() {
      return this.companyHasAutoDeletionEnabled && !this.companyHasLegacyAutoDeletion && this.daysUntilDeletion !== null && this.daysUntilDeletion < 8;
    },
    showLegacyAutoDeleteIndicator: function showLegacyAutoDeleteIndicator() {
      return this.companyHasLegacyAutoDeletion && ['declined', 'withdrawn', 'completed'].includes(this.status);
    }
  },
  created: function created() {
    if (!this.signatureRequest.expirationDate) return;
    // Get current datetime from server and pass it on to document previews
    // so we can calculate accurate time until deletion of individual documents
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var ds = "".concat(this.signatureRequest.expirationDate, "T03:00:00Z");
    var diff = differenceInDays(utcToZonedTime(ds, tz, {
      timeZone: 'UTC'
    }), utcToZonedTime(this.now, tz, {
      timeZone: 'UTC'
    }));
    if (diff < 0) diff = 0;
    this.daysUntilDeletion = diff;
  }
});